import React,{useState,useEffect} from "react";
import "./PopularBlogs.css"
import BlogCard from './BlogCard'
import PopularBlogCard from './PopularBlogCard'
import axios from "axios"
import {useNavigate} from "react-router-dom"
import {Spinner} from "react-bootstrap"


let defaultBlogObj = []

function PopularBlogs() {

    const [blogs, setBlogs] = useState([]);
    const [recentBlogs, setRecentBlogs] = useState([]);
  
    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();
  
    let blogAPICalled = false;
  
    useEffect(() => {
      getAllBlogsListing();
    }, []);
  
    const getAllBlogsListing = () => {
      if (blogAPICalled) return;
      blogAPICalled = true;
  
      setLoading(true);
      axios
        .get("/blogs", {})
        .then((res) => {
          if (res.data.status === "success") {
            let Updated_data_Blogs = [];
            let Updated_recent_blogs = [];
            res.data.data.forEach((item) => {
              Updated_data_Blogs.push({
                id: item.blog_id,
                main_heading: item.title,
                paragraph: item.brief_paragraph,
                logo: "/favicon.png",
                entity: "Creative Squad",
                date: item.published_date,
                slug_url: item.slug_url,
                category: item.category,
                blog_creative: item.blog_image,
              });
            });
            res.data.data.forEach((item) => {
              Updated_recent_blogs.push({
                id: item.blog_id,
                slug_url: item.slug_url,
                logo: item.blog_image,
                blog_description: item.title,
                date: item.published_date,
              });
            });
            setRecentBlogs(Updated_recent_blogs.slice(0, 3));
            setBlogs(Updated_data_Blogs);
            defaultBlogObj = Updated_data_Blogs;
            setLoading(false);
          }
            
        })
        .catch((err) => {
          console.log("eror =>",err);
          setLoading(false);
        });
    };
  
    const convertToSlug = (str) => {
      return str.toLowerCase().replace(/\s+/g, "-");
    };
  
    const redirectUserToBlog = (id) => {
      if (!id) return;
      let slug = convertToSlug(id);
  
      navigate(`/${slug}`);
    };

  return (
    <div className='popular-blog-container standard-padding-space standard-margin-space'>
        <div className='container'>
        {loading ? 
        (<div className="w-100 text-center mb-5">
          <Spinner
            style={{ color: "#85329A", width: "120px", height: "120px" }}
          />
        </div>):
          (
            <>
            <div className='row justify-content-between'>
                <div className='col-md-6 blog-list' 
                // style={{maxHeight:"800px",overflowY:"auto", scrollbarWidth: "thin", msOverflowStyle: "none"}}
                >
                    

                    {
                        blogs.map((ele)=>{
                            return(
                                <BlogCard
                                    title={ele.main_heading}
                                    anchor={ele.slug_url}
                                    redirectionFunction={redirectUserToBlog}
                                    date={ele.date}
                                    content={ele.paragraph}
                                    img={ele.blog_creative}
                                />)
                        })
                    }

                </div>
                <div className='col-md-5'>
                    <h3 className='body-heading text-md-start text-center'>Most Popular</h3>

                    {
                        recentBlogs.map((ele)=>{
                            return(
                                <PopularBlogCard
                                    img={ele.logo}
                                    title={ele.blog_description}
                                    date={ele.date}
                                    anchor={ele.slug_url}
                                    redirectionFunction={redirectUserToBlog}
                                />
                            )
                        })
                    }
                    
                </div>
            </div>

            </>
          )}
            
        </div>
    </div>
  )
}

export default PopularBlogs