import React from 'react'
import "./ServicesText.css"
import { useNavigate } from 'react-router-dom'

function ServicesText() {
    const navigate = useNavigate()
  return (
    <div className='service-text-container standard-margin-space '>
        <div className='container-width py-4 px-4'>
            <div className='text-content text-center pt-3 '>
                <p className='sub-heading'>OUR SERVICES</p>
                <p className='body-paragraph w-75 m-auto mb-3 mb-md-0'>Take charge of your pelvic health, regain your confidence, and enjoy a life free from the constraints of bladder leakage and sexual dysfunction.</p>
            </div>
            
            <div className='card-container d-flex flex-row justify-content-evenly align-items-end gap-3 my-2 mb-4'>
                    <div className='con'>
                        <div className='card-box card-box-1 d-flex flex-row align-items-end'>
                            <img src='/images/creatives/services-text-img-1.png' alt='person ' className='card-img'/>
                            <img src='/images/creatives/service-men-line.png' alt='design line' className='line-img' style={{height:"inherit"}} />
                            <div className='services-card-text py-2 py-sm-0 px-4 align-self-center mx-auto'>
                                <h5 className='title'>Elev8te Men’s Therapy</h5>
                                <p className='body-paragraph mb-2'>We understand the challenges men face with urinary incontinence and erectile dysfunction.</p>
                                <p className='body-paragraph services-anchor mb-0' onClick={()=>{navigate("/how-it-works-for-men")}}>
                                    Learn More
                                    <img src='/images/icons/service-text-arrow-icon.png' alt='btn arrow icon'  style={{width:"20px",height:"20px",marginLeft:"5px"}}/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='con'>
                        <div className='card-box d-flex d-flex flex-row align-items-end' id='column-rev'>
                            <div className='services-card-text py-2 py-sm-0 px-4 align-self-center mx-auto'>
                                <h5 className='title'>Women Wave Therapy</h5>
                                <p className='body-paragraph mb-2'>We understand the challenges women face with bladder leakage and pelvic floor weakness.</p>
                                <p className='body-paragraph services-anchor mb-0' onClick={()=>{navigate("/forwomen")}}>
                                    Learn More
                                    <img src='/images/icons/service-text-arrow-icon.png' alt='btn arrow icon'  style={{width:"20px",height:"20px",marginLeft:"5px"}}/>    
                                </p>
                            </div>
                            <img src='/images/creatives/service-women-line.png' alt='design icon' className='line-img' style={{height:"inherit"}} />
                            <img src='/images/creatives/services-text-img-2.png' alt='person' className='card-img'/>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesText