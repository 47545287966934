import React from 'react'
import "./HowText.css"

function HowText({
    heading="",
    content=""
}) {
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  return (
    <div className='how-text-container standard-margin-space'>
        <div className='text-content text-center standard-padding-space '>
            <h3 className='title my-3 mb-4'>{heading}</h3>
            <div className='w-75 mx-auto'>{paragraphs}</div>
        </div>

    </div>
  )
}

export default HowText