import React from 'react'
import axios from "axios"


const downloadFile = async (filename) => {
  try {
    const response = await axios.get(`/research-download/${filename}`);
    
    const link = document.createElement('a');
    link.href = (`/research-download/${filename}`);
    link.download = filename;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  } catch (error) {
    alert('Error downloading file:', error);
  }
};

function PdfListBox({
    para="",
    pdf
}) {
  return (
    <div className='pdf-card-box d-flex flex-column flex-lg-row mx-auto align-items-center'>
        <img src='/images/icons/PDF-list-icon.png' className='pdf-icon' alt='pdf-icon'/>
        <div className='py-3 text-center text-lg-start px-3 px-lg-auto' style={{paddingRight:"10px"}}>
            <p className='body-paragraph' style={{fontSize:"14px"}}>{para}</p>
            <div className='d-flex align-items-center justify-content-lg-start justify-content-center gap-3' style={{cursor:"pointer"}}>
                <img src='/images/icons/pdf-download-icon.svg' alt='pdf-download-icon'/>
                <p className='anchor-text mb-0' onClick={()=>{downloadFile(pdf)}}>DOWNLOAD NOW</p>
            </div>
        </div>
    </div>
  )
}

export default PdfListBox