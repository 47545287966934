import React, { useState } from "react";
import "../Popup/Popup.css";
import Popup from '../Popup/Popup'
import ContactForm from "../ContactForm/ContactForm";

function AppointmentModal({ setOpenModal, ModalHeading="Book an Appointment" }) {
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    age: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to backend
    console.log(formData);
    setOpenModal(false); // Close modal after form submission
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          {/* <button onClick={() => setOpenModal(false)}>X demo</button> */}
        </div>
        <div className="title">
          
        </div>
        <div className="body">
        <Popup modalHeading={ModalHeading} closePopup={() => setOpenModal(false)} /> {/* Pass closePopup function */}
        </div>
        <div className="footer">
        <button id="cancelBtn" onClick={() => setOpenModal(false)}>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AppointmentModal;
