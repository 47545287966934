

 
function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}


function CardBox(props) {
  const breifTitle = truncateText(props.title,50)
  return (
    <div className="card-box">
      <div className="w-100">
        <img src={props.cardImg} alt="someImage" />
        <div className="card-text-content">
          <div className="mb-0 mt-3 d-flex align-items-center gap-1"><img src="/images/icons/admin-icon.svg" alt="person icon" className="admin-icon"/><p className="author mb-0" style={{fontSize:"15px",fontWeight:"500",color:"#141414"}}>Jacquie Boddaert</p></div>
          <h5 style={{fontSize:"20px",fontWeight:"600",color:"#141414"}}>{breifTitle}</h5>
        </div>
      </div>
        <div className="blog-info d-flex align-items-center justify-content-between mt-3">
          <div className="mb-0 mt-1 d-flex align-items-center gap-1"><img src="/images/icons/calender-icon.svg" alt="calendar icon" className="date-icon mb-0"/><p className="pb-date mb-0" style={{fontSize:"16px",fontWeight:"500",color:"#959595"}}>{props.publishDate}</p></div>
          <a className="read-more-anchor color-yellow" style={{fontSize:"16px",fontWeight:"600"}}  onClick={()=>{props.redirectionFunction(props.anchor)}}>READ MORE</a>
        </div>
    </div>
  )
}

export default CardBox