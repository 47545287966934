import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function NotFound() {
  return (
    <>
   
        <HeroBanner
            subHeading=""
            Heading="404 Not Found"
            nextLineSpanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creatives/notFound-hero-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={false}
            appointmentBtn={false}
        />
        <Footer
          newsletterSection={false}
        />
        
    </>
  )
}

export default NotFound