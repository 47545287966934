import React from 'react'
import "./Sponsor.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Sponsor() {
  return (
    <div className='sponsor-container standard-margin-space py-1'>
      <div className='py-2'></div>
        <div className="companies-logo-box ">
        <Swiper
            spaceBetween={10}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 5,
                }
              }}
            className="mx-auto"
            >
                <SwiperSlide>
                <img src="/images/icons/logo-1.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-2.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-3.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-4.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-5.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-6.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-7.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-8.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-9.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-10.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-11.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-12.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-13.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-14.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-15.png" alt="partner company logo" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/logo-16.png" alt="partner company logo" />
                </SwiperSlide>
                
                  
            </Swiper>
        </div>
        <div className='py-2'></div>
    </div>
  )
}

export default Sponsor