import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer';

function PrivacyPolicy() {
  return (
    <>
        <HeroBanner
            Heading="Privacy Policy"
            subHeading=""
            spanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creatives/plane-light-hero-bg.png"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            isChevron={false}
        />

        <div className='container'>
            <p className='body-pragraph'>Welcome to Venus Health Medical.</p>
            <p className='body-pragraph'>We are committed to protecting the personal information that you provide to us (this includes personal health information).This Privacy Policy (“Notice”) explains how we collect, use and disclose your personal information.  The information contained in this Notice applies to you if you have used our “Services” ( visited Venus Health Medical in person, your visits to: <a href="https://venushealthmedical.com">https://venushealthmedical.com</a> called-in, sent a form or emailed us and provided us with your personal information).</p>
            <p className='body-pragraph'>Our Privacy Policy explains how we collect, safeguard and disclose information that results from your use of our Services.We use your data to provide and improve Service. By using our Services, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>


            <h3 className='body-heading-privacy'>1. INTRODUCTION</h3>
            <p className='body-pragraph'>Our Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (“Agreement”).</p>

            <h3 className='body-heading-privacy'>2. DEFINITIONS</h3>
            <p className='body-pragraph'>SERVICE means visited Venus Health Medical in person, your visits to: <a href="https://venushealthmedical.com">https://venushealthmedical.com</a> called-in, sent a form or emailed us and provided us with your personal information.</p>
            <p className='body-pragraph'>PERSONAL DATA means data about a living individual who can be identified from the data (or from those and other information either in our possession or likely to come into our possession).</p>
            <p className='body-pragraph'>USAGE DATA is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
            <p className='body-pragraph'>COOKIES are small files stored on your device (computer or mobile device).</p>
            <p className='body-pragraph'>DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
            <p className='body-pragraph'>DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
            <p className='body-pragraph'>DATA SUBJECT is any living individual who is the subject of Personal Data.</p>
            <p className='body-pragraph'>THE USER is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>

            <h3 className='body-heading-privacy'>3. INFORMATION COLLECTION AND USE</h3>
            <p className='body-pragraph'>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

            <h3 className='body-heading-privacy'>4. SERVICE PROVIDERS</h3>
            <p className='body-pragraph'>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
            <p className='body-pragraph'>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

            <h3 className='body-heading-privacy'>5.  ANALYTICS</h3>
            <p className='body-pragraph'>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

            <h3 className='body-heading-privacy'>6. CI/CD TOOLS</h3>
            <p className='body-pragraph'>We may use third-party Service Providers to automate the development process of our Service.</p>

            <h3 className='body-heading-privacy'>7. PAYMENTS</h3>
            <p className='body-pragraph'>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g., payment processors).</p>
            <p className='body-pragraph'>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>

            <h3 className='body-heading-privacy'>8. LINKS TO OTHER SITES</h3>
            <p className='body-pragraph'>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
            <p className='body-pragraph'>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

            <h3 className='body-heading-privacy'>9. CHILDREN’S PRIVACY</h3>
            <p className='body-pragraph'>Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).</p>
            <p className='body-pragraph'>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>

            <h3 className='body-heading-privacy'>10. CHANGES TO THIS PRIVACY POLICY</h3>
            <p className='body-pragraph'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p className='body-pragraph'>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
            <p className='body-pragraph'>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            <p className='body-pragraph'>We restrict access to your personal information to those employees who need to know that information for the Purpose described below. We maintain physical and electronic safeguards that comply with applicable laws to guard your personal information.  To the extent we contract with third party service providers, we ensure that appropriate privacy protection is in place to safeguard against any inadvertent access to your personal information.</p>

            <h3 className='body-heading-privacy'>11. PURPOSE</h3>
            <p className='body-pragraph'>We collect and use your personal information for the purpose of providing direct client health care; client billing; planning, administration and management of Venus Health Medical services; conducting quality and service improvement activities (fo example – sending client satisfaction surveys); compliance with legal and regulatory requirements; and as permitted or required by law (hereinafter referred to as the “Purpose”).</p>

            <h3 className='body-heading-privacy'>12.  INFORMATION WE COLLECT AND USE</h3>
            <p className='body-pragraph'>Venus Health Medical limits the collection of personal information to that which is necessary to fulfill the Purpose identified in paragraph 2 and in accordance with the requirements set out by applicable law.</p>
            <p className='body-pragraph'>Personal information is collected directly from you, unless the law permits or requires collection from third parties, custodians or other healthcare providers.</p>
            <p className='body-pragraph'>We may collect the following personal health care related information from you:</p>
            <ul>
                <li>
                <p className='body-pragraph'>your health number and information related to your health number;</p>
                </li>
                <li>
                <p className='body-pragraph'>your physical or mental health, including information that consists of the health history of your family;</p>
                </li>
                <li>
                <p className='body-pragraph'>information relating to the provision of health care, including the identification of a person as a provider of health care for you;</p>
                </li>
                <li>
                <p className='body-pragraph'>information regarding insurance, payment information, or eligibility for health care, or eligibility for coverage of health care;</p>
                </li>
                <li>
                <p className='body-pragraph'>information relating to a person who is acting on your behalf for treatment purposes (for example: or your next-of-kin, if appropriate); and</p>
                </li>
                <li>
                <p className='body-pragraph'>information surrounding or relating to the donation of any bodily substance or derived from the testing or examination of any bodily substance.</p>
                </li>
            </ul>
            <p className='body-pragraph'>In each case we use this personal information for the Purpose, and as otherwise permitted or required by applicable law.</p>
            <p className='body-pragraph'>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>

            <ul>
                <li>
                    <p className='body-paragraph'>
                    Email address
                    </p>
                </li>
                <li>
                    <p className='body-paragraph'>
                    First name and last name
                    </p>
                </li>
                <li>
                    <p className='body-paragraph'>
                    Phone number
                    </p>
                </li>
                <li>
                    <p className='body-paragraph'>
                    Address, Country, State, Province, Postal Code, City
                    </p>
                </li>
                <li>
                    <p className='body-paragraph'>
                    Cookies and Usage Data
                    </p>
                </li>
            </ul>
            <p className='body-paragraph'>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.</p>


            <h3 className='sub'>Usage Data</h3>
            <p className='body-pragraph'>We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (“Usage Data”).</p>
            <p className='body-pragraph'>This Usage Data may include information such as your computer’s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p className='body-pragraph'>When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.</p>

            <h3 className='sub'>Location Data</h3>
            <p className='body-pragraph'>We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.</p>
            <p className='body-pragraph'>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>

            <h3 className='sub'>Tracking Cookies Data</h3>
            <p className='body-pragraph'>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
            <p className='body-pragraph'>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>
            <p className='body-pragraph'>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

            <h3 className='sub'>Examples of Cookies we use:</h3>
            <ul>
                <li>
                    <p className='body-paragraph'>Session Cookies: We use Session Cookies to operate our Service.</p>
                </li>
                <li>
                    <p className='body-paragraph'>Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</p>
                </li>
                <li>
                    <p className='body-paragraph'>Security Cookies: We use Security Cookies for security purposes.</p>
                </li>
                <li>
                    <p className='body-paragraph'>Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</p>
                </li>
            </ul>

            <h3 className='sub'>Other Data</h3>
            <p className='body-pragraph'>While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.</p>

            <h3 className='body-heading-privacy'>13. USE OF DATA</h3>
            <p className='body-pragraph'>Venus Health Medical uses the collected data for various purposes:</p>
            <ul>
                <li>
                    <p className='body-paragraph'>to provide and maintain our Service;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to notify you about changes to our Service;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to allow you to participate in interactive features of our Service when you choose to do so;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to allow you to participate in interactive features of our Service when you choose to do so;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to gather analysis or valuable information so that we can improve our Service;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to monitor the usage of our Service;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to detect, prevent and address technical issues;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to fulfill any other purpose for which you provide it;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</p>
                </li>
                <li>
                    <p className='body-paragraph'>to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</p>
                </li>
                <li>
                    <p className='body-paragraph'>in any other way we may describe when you provide the information;</p>
                </li>
                <li>
                    <p className='body-paragraph'>for any other purpose with your consent.</p>
                </li>
            </ul>

            <h3 className='body-heading-privacy'>14. INFORMATION WE DISCLOSE</h3>
            <p className='body-pragraph'>Venus Health Medical operates within the Circle of Care model. This means that implied consent is assumed when providing health care or assisting in providing health care within the Circle of Care (to a health information custodian or to a person who is responsible for providing continuing treatment and care to you). This is unless Venus Health Medical is aware and has documented in writing that you have specifically withdrawn or withheld your consent (see “Consent” in section 5 below).</p>
            <p className='body-pragraph'>We may disclose personal information that we collect, or you provide:</p>

            <h3 className='sub'>Disclosure for Law Enforcement.</h3>
            <p className='body-pragraph'>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p>

            <h3 className='sub'>Business Transaction.</h3>
            <p className='body-pragraph'>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</p>

            <h3 className='sub'>Other cases. We may disclose your information also:</h3>
            <ul>
                <li>
                    <p className='body-pragraph'>to our subsidiaries and affiliates;</p>
                </li>
                <li>
                    <p className='body-pragraph'>to contractors, service providers, and other third parties we use to support our business;</p>
                </li>
                <li>
                    <p className='body-pragraph'>to fulfill the purpose for which you provide it;</p>
                </li>
                <li>
                    <p className='body-pragraph'>for the purpose of including your company’s logo on our website;</p>
                </li>
                <li>
                    <p className='body-pragraph'>for any other purpose disclosed by us when you provide the information;</p>
                </li>
                <li>
                    <p className='body-pragraph'>with your consent in any other cases;</p>
                </li>
                <li>
                    <p className='body-pragraph'>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</p>
                </li>
            </ul>


            <h3 className='body-heading-privacy'>15. CONSENT</h3>
            <p className='body-pragraph'>Where you do not wish us to collect, use or disclose your personal information for specified purposes, you can opt-out from such collection, use and disclosure.  Please let us know in writing and we will ensure that this request is documented in our patient files. The files (could be physical or electronic) containing your personal information will be securely maintained by Venus Health Medical and its authorized employees or agents who need to access the information in the administration and provision of the services we provide to you.</p>

            <h3 className='body-heading-privacy'>16. RETENTION OF DATA</h3>
            <p className='body-pragraph'>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <p className='body-pragraph'>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>

            <h3 className='body-heading-privacy'>17. SECURITY OF DATA</h3>
            <p className='body-pragraph'>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

            <h3 className='body-heading-privacy'>18. SERVICE PROVIDERS</h3>
            <p className='body-pragraph'>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
            <p className='body-pragraph'>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

            <h3 className='body-heading-privacy'>19. ANALYTICS</h3>
            <p className='body-pragraph'>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

            <h3 className='body-heading-privacy'>20. CI/CD TOOLS</h3>
            <p className='body-pragraph'>We may use third-party Service Providers to automate the development process of our Service.</p>

            <h3 className='body-heading-privacy'>21. PAYMENTS</h3>
            <p className='body-pragraph'>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g., payment processors).</p>
            <p className='body-pragraph'>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>

            <h3 className='body-heading-privacy'>22. LINKS TO OTHER SITES</h3>
            <p className='body-pragraph'>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
            <p className='body-pragraph'>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

            <h3 className='body-heading-privacy'>23. CHILDREN’S PRIVACY</h3>
            <p className='body-pragraph'>Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).</p>
            <p className='body-pragraph'>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>

            <h3 className='body-heading-privacy'>24. CHANGES TO THIS PRIVACY POLICY</h3>
            <p className='body-pragraph'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p className='body-pragraph'>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
            <p className='body-pragraph'>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

            <h3 className='body-heading-privacy'>25.   YOUR RIGHTS</h3>
            <p className='body-pragraph'>You have a right to access and rectify the information contained in a file held about you.</p>
            <p className='body-pragraph' style={{textDecoration:"underline"}}>Access to Your Information</p>
            <p className='body-pragraph'>To obtain formal access to your information, you must make a request in writing to Venus Health Medical. We may ask for you to provide us with further information to process the request or ask that you pay a fee for processing the request as set out in the applicable legislation.</p>
            <p className='body-pragraph'>We will make every reasonable effort to assist you and to respond openly, accurately and completely.</p>
            <h3 className='sub'>We will create a record for you if:</h3>
            <ul>
                <li>
                    <p className='body-paragraph'>It can be created from information that is in electronic form and it is in our custody or control, using normal computer hardware and software and technical expertise, and</p>
                </li>
                <li>
                    <p className='body-paragraph'>creating the record would not unreasonably interfere with the operations of Venus Health Medical.</p>
                </li>
            </ul>

            <h3 className='sub'>We may refuse to disclose information to you, if:</h3>
            <p className='body-paragraph'>(a) the record is subject to a legal privilege that restricts disclosure;</p>
            <p className='body-paragraph'>(b) another Act or a court order prohibits disclosure;</p>
            <p className='body-paragraph'>(c) the information was collected or created primarily in anticipation of use in a proceeding, and the proceeding (including appeals) have not been concluded;</p>
            <p className='body-paragraph'>(d) the following conditions are met:</p>
            <ul>
                <li>
                    <p className='body-paragraph'>the information was collected in the course of an inspection, investigation or similar procedure, and</p>
                </li>
                <li>
                    <p className='body-paragraph'>the inspection, investigation, or similar procedure have not been concluded;</p>
                </li>
                <li>
                    <p className='body-paragraph'>granting the access could reasonably be expected to,</p>
                </li>
                <li>
                    <p className='body-paragraph'>result in a risk of serious harm to you or another person,</p>
                </li>
                <li>
                    <p className='body-paragraph'>lead to the identification of a person who was required by law to provide information in the record to us, or</p>
                </li>
                <li>
                    <p className='body-paragraph'>lead to the identification of a person who provided information in the record to us in confidence.</p>
                </li>
            </ul>
            <p className='body-paragraph'>Please note that under the law, we must make every reasonable effort to respond to a request within a set number of days after receiving the request or within any extended period.</p>
            <p className='body-paragraph' style={{textDecoration:"underline"}}>Nothing prevents Venus Health Medical from granting you access to your personal information if you make an oral request, informal request.</p>
            <p className='body-paragraph' style={{textDecoration:"underline"}}>Correction or Amendment of Your Information</p>
            <p className='body-pragraph'>If you believe there is an error or omission in your information, you may request in writing that we amend the information.</p>
            <p className='body-pragraph'>We must respond to a request to correct a record with a set number of days as set out in the applicable legislation, or seek an extended period of time.</p>
            <p className='body-pragraph'>We also have the right to refuse to make the correction or amendment as set out by law. If we refuse to make the correction or amendment, we will provide you with written notice, including the reasons for the refusal.</p>
            <p className='body-pragraph'>We can refuse to make a correction or amendment that has been requested in respect of:</p>
            <p className='body-pragraph'>(a)  a professional opinion or observation made by a health services provider about you, or</p>
            <p className='body-pragraph'>(b) a record that was not originally created by Venus Health Medical</p>
            <p className='body-pragraph'>If we refuse to correct a record, you can either:</p>
            <p className='body-pragraph'>(c) ask for a review of Venus Health Medical’S decision by the Commissioner; or</p>
            <p className='body-pragraph'>(d) submit a statement of disagreement setting out the requested correction or amendment and your reasons for disagreeing with our decision.</p>
            <p className='body-pragraph'>In order to exercise this right and in order to obtain information about our privacy practices and about our collection, use and disclosure of personal information, both by us and any service providers we contract with in Ontario or in other provinces, you can contact us at:</p>

            <a className='sub sub-link' href='mailto:info@venushealthmedical.com'>info@venushealthmedical.com</a>
            <p className='body-pragraph'>For a copy of the Personal Health Information Protection Act, 2004 see: <a target='_blank' href='https://www.ontario.ca/laws/statute/04p03'>https://www.ontario.ca/laws/statute/04p03</a> </p>
            <p className='body-pragraph'>To contact the <b>Information and Privacy Commissioner of Ontario:</b></p>
            <p className='body-pragraph'>2 Bloor Street East, Suite 1400</p>
            <p className='body-pragraph'>Toronto, Ontario</p>
            <p className='body-pragraph'>M4W 1A8</p>
            <p className='body-pragraph'>Phone: 416-326-3333 or 1-800-387-0073</p>
            
            <div style={{height:"80px"}}>

            </div>


        </div>

        <Footer
            newsletterSection={false}
        />
    </>
  )
}

export default PrivacyPolicy