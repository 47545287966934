import React,{useState} from 'react'
import "./BgTextContent.css"
import AppointmentModal from '../AppointmentModal/AppointmentModal';

function BgTextContent() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className='BgTextContent-container standard-padding-space standard-margin-space'>
        <div className='container my-5'>
            <div className='text-content text-center py-5'>
                <h3 className='body-heading my-3 px-3 px-lg-1' style={{color:"white"}}>Is Venus Wave Therapy Eligible For Coverage With Extended Health Benefits?</h3>
                <p className='body-paragraph px-3 px-lg-5' style={{color:"white"}}>Venus Wave Therapy Physiotherapy Services are eligible for extended health benefit coverage. Each plan is of course unique, so it’s up to the client to understand what level of coverage they have.</p>
               
                <button className="content-btn mt-2" onClick={()=>{setModalOpen((curr)=> !curr)}}>BOOK AN APPOINTMENT</button>
                {modalOpen && <AppointmentModal setOpenModal={setModalOpen} />}
                
            </div>

        </div>
    </div>
  )
}

export default BgTextContent