import React,{useState} from 'react'
import "./TextContent.css"
import { useNavigate } from 'react-router-dom';
import AppointmentModal from '../AppointmentModal/AppointmentModal';

function TextContent({
  heading,
  subHeading="",
  postSubHeading="",
  postHeading="",
  postContent="",
  content,
  btn=false,
  btnText,
  btnRef="/",
  btnRefTel=false,
  shiftRow=false,
  img,
  reviewContent= false,
  review,
  reviewUser,
  userImg,
  mobileTextCenter=true
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className='text-content-section standard-padding-space'>
      <div className='container-space'>
        <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
          <div className={`col-lg-6 p-2 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
            <img src={img} className='img-fluid' alt='content'/>
          </div>
          <div className='col-lg-6'>
            <div className={`text-content ${mobileTextCenter && "text-center text-lg-start"} `}>
              <p className='sub-heading mb-0'>{subHeading}</p>
              {/* <div className='paddLeft'> */}
              <div className='paddLef'>
                <h3 className='body-heading my-2 mt-3 '>{heading}</h3>
                <p className='sub-heading mb-3 mb-xxl-4' style={{fontSize:"35px",fontWeight:"600"}}>{postSubHeading}</p>
                <div className=''>{paragraphs}</div>
                <h3 className='body-heading mt-3 my-xl-3 mt-xl-4'>{postHeading}</h3>
                <p className='body-paragraph my-1 mb-3'>{postContent}</p>
                {
                  btnRefTel ?
                  (
                    <>
                    {btn && 
                      <>
                      <button className='text-btn mt-2' onClick={()=>{setModalOpen(true);}}>{btnText}</button>
                      {modalOpen && <AppointmentModal setOpenModal={setModalOpen} ModalHeading={btnText}/>}
                      </>}
                    </>

                  ):
                  (
                    <>
                    {btn && <button className='text-btn mt-2' onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </>
                  )
                }
              </div>
              {
                reviewContent &&
                <>
                  <div className='line-seprator my-2 mt-4'></div>
                  <div className='d-flex align-items-start gap-3 pt-4'>
                    {/* <img src={userImg} alt="person"/> */}
                    <div className='my-3'>
                      <p className='body-paragraph mb-1'>{review}</p>
                      {/* <p className='body-paragraph mb-0'>{reviewUser}</p> */}
                    </div>
                  </div>
                  <button className='text-btn mt-3' onClick={()=>{navigate("/about")}}>ABOUT US</button>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextContent