import React from 'react'

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}

function BlogCard({
  title,
    catagory,
    date,
    content,
    img,
    blogRoute,
    anchor,
    redirectionFunction
}) {

  const breifPara = truncateText(content,120)
  const breifTitle = truncateText(title,50)

  return (
    <div className='blog-card mb-5'>
      <h3 className='body-heading text-md-start text-center pb-2'>{breifTitle}</h3>
        <div className="blog_list_logo d-flex flex-column flex-md-row align-items-center justify-content-md-start justify-content-center">
          {/* <img src="/images/icons/nav-logo.png" alt="logo" className="blog_list_logo_img"/> */}
          <div className='d-flex flex-column flex-md-row align-items-center justify-content-between w-100 px-1'>
            <div className="blog_list_logo_text text-center text-md-start">
              <h6 className="blog_list_logo_title m-0 font-bebas">VENUS</h6>
              <p className="blog_list_logo_date m-0">{date}  <span className='color-blue'>{catagory}</span></p>
            </div>
            <button className='genral-btn my-md-0 my-3' onClick={()=>{redirectionFunction(anchor)}}>READ MORE</button>
          </div>
        </div>
        <img src={img} className='card-img my-3' alt='card-img'/>
        <div className='body-paragraph text-md-start text-center ' dangerouslySetInnerHTML={{__html : breifPara}}></div>
        {/* <p className='body-paragraph text-md-start text-center '>{breifPara}</p> */}
    </div>
  )
}

export default BlogCard