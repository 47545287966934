import React from "react";
import "./modal.css";

export default function Modal(props) {

  return (
    <div className="modal-container" style={props.status ? {display : "block"} : {display:"none"}}>
      <div className="overlay" onClick={props.closeModal}></div>
      <div className="modal-main d-flex justify-content-center align-items-center w-fit">
          
       <iframe className="iframe-vid" src={props.videoSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    </div>
    );
}