import React from 'react'
import DropDownBox from "./DropDownBox"
import "./FaqSection.css"

function FaqSection({  
    heading,
    subHeading,
    showImg=false,
    faqData=[]
}) 
{
    

  return (
    <div className='faq-container standard-padding-space mt-5'>
        <div className='text-content d-flex flex-column align-items-center w-75 mx-auto'>
            <h3 className='body-heading'>{heading}</h3>
            <h6 className='sub-heading my-4' style={{color : "#8740A5"}}>{subHeading}</h6>
            {showImg && <img src='/images/icons/faq-user-img.png' className='user-img mt-4' alt='faq-user-icon'/>}

        </div>
        <div className='dropdown-container row mt-4'>
            {
                faqData.map((ele)=>{
                    return(
                        <div className='col-xl-6'>
                            <DropDownBox title={ele.title} content={ele.content} />
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default FaqSection