import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import HowText from '../component/HowText/HowText'
import WomenBookAppointment from '../component/WomenBookAppointment/WomenBookAppointment'
import Footer from '../component/Footer/Footer'
import VideoContent from '../component/VideoContent/VideoContent'
import BgTextContent from '../component/BgTextContent/BgTextContent'
import { Helmet } from 'react-helmet';

function ForWomen() {
  return (
    <>
    <Helmet>
    <title>Bladder Leakage Treatment Toronto | Venus Treatment</title>
    <meta name='description' content="Trusted partner in women's health. We address bladder leakage & pelvic floor weakness. Get solutions & regain confidence. Contact us for a consultation!" />
    <link rel="canonical" href="https://venushealthmedical.com/forwomen" />
    <meta name="keywords" content="Pelvic Chair For Bladder Leakage Toronto"></meta>
  </Helmet>
      <HeroBanner 
        subHeading=""
        Heading="FOR WOMEN"
        nextLineSpanHeading=""
        Paragraph="Your Trusted Partner in Women's Health. We understand the challenges women face with bladder leakage and pelvic floor weakness. Our mission is a healthier, more confident you!"
        btnText="BOOK AN APPOINTMENT"
        btnRefJnap={true}
        bgImg="/images/creatives/women-hero-bg.png"
        btn={true}
      />
  <div className='left-gradient'>
      <TextContent
         heading= "Pelvic Chair For Bladder Leakage"
         subHeading=""
         postSubHeading="& Enhance Your Sexual Performance"
         content={"Loss of bladder control (bladder leakage) leads to uncontrolled bladder leakage, which is a common and often unpleasant problem. \n The severity of this problem usually starts with the occasional bladder leakage when coughing or sneezing, or when you have an “urgent” need to urinate, suddenly and strongly, so sometimes you do not get to the bathroom on time. \n If uncontrolled bladder leakage affects your daily activities, you should not hesitate to contact a Venus Wave Therapy consultant to schedule a no-charge consultation about pelvic chair benefits. At that appointment, all of your questions will be answered and your individualized treatment plan will be laid out."}
         btn={true}
         btnText="Free Assesment"
         btnRefTel={true}
         shiftRow={true}
         img="/images/creatives/women-text-1.png"
      />
        <BgTextContent/>
        {/* <HowText
            heading='How Does It Work?'
            content={"All you need to do is sit (while clothed) on our treatment “throne”… relax…. and it will do its job. The treatment chair strengthens your pelvic floor muscle as it produces 11,000 perfect Kegels in just 28 minutes. Yes, you read that right. The chair will generate electromagnetic wave energy that moves through your body and causes the pelvic floor muscles to contract. \n Not only will you gain control over your bladder, but it will also enhance your sexual pleasure. \n You will feel significant improvements after your first session, and with each subsequent visit you will feel better and better."}
        /> */}

        <div className='container standard-padding-space my-5'>
          <div className='text-content text-center px-3'>
            <h3 className='body-heading my-3' style={{color:"#8740A5"}}>How Does It Work?</h3>
            <p className='body-paragraph'>All you need to do is sit (while clothed) on our treatment “throne”… relax…. and it will do its job. The treatment chair strengthens your pelvic floor muscle as it produces 11,000 perfect Kegels in just 28 minutes. Yes, you read that right. The chair will generate electromagnetic wave energy that moves through your body and causes the pelvic floor muscles to contract. </p>

            <p className='body-paragraph'>Not only will you gain control over your bladder, but it will also enhance your sexual pleasure.</p>
            <p className='body-paragraph'>You will feel significant improvements after your first session, and with each subsequent visit you will feel better and better.</p>
          </div>
        </div>
</div>
<div className='right-gradient'>

      <TextContent
         heading= {<h2>Innovation That Can Stop Bladder Leaks</h2>}
         subHeading=""
         content={"Did you know that sitting on our ‘Kegel Throne’ (approved by The FDA and Health Canada) can finally stop these problems with your leaky bladder? The “treatment chair” adapts to one’s back, fits the spine, and provides comfort. It uses High-Intensity Focused Electromagnetic Waves to do it’s magic."}
         postHeading='Is It Painful?'
         postContent='The treatment does not cause any discomfort. You can simply sit, relax, and enjoy your treatment.'
         btn={true}
         btnText="Free Assesment"
         btnRefTel={true}
         shiftRow={false}
         img="/images/creatives/women-text-2.png"
         reviewContent={false}
      />

      <WomenBookAppointment/>
</div>
<div className='bottom-gradient'>

        <VideoContent
            heading="Regain Control, Rediscover Confidence"
            subHeading="Pelvic Chair Treatment for Women"
            content="Are you experiencing bladder leakage, disrupting your daily life and causing discomfort? The occasional bladder leakage, especially during activities like coughing or sneezing, or the sudden and urgent need to urinate, can be distressing. If these issues affect your routine, it's time to explore the benefits of our revolutionary Pelvic Chair treatment."
            btn={true}
            shiftRow={true}
            btnText="Free Assesment"
            btnRefTel={true}
            img="/images/creatives/video-thumbnail-image-for-women.webp"
            reviewContent={false}
            videoSrc={"https://www.youtube.com/embed/hIBvWQ3Yrw0?si=vMwA3InTO-3L_BWH&autoplay=1"}
        />
      <div className='' style={{height:"40px"}}></div>
      <Testimonials/>
      <Footer/>
      </div>
    </>
  )
}

export default ForWomen