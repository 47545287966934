import React,{useState} from 'react'
import "./TextCard.css"

function TextCardBox({
    title,
    iconImg,
    breifPara,
    actualPara
}) {
    const [dropOpen , setDropOpen] = useState(false)
  return (
    <div
      className="text-card-box p-4 px-5 d-flex flex-column align-items-start"
      onClick={() => {
        setDropOpen((prev) => !prev);
      }}
    >
      <div className="content-info d-flex gap-3 align-items-center justify-content-start my-4">
        <img src={iconImg} className="" alt='icon-img'/>
        <h5 className="title">{title}</h5>
      </div>
      <p
        className={`body-paragraph ${dropOpen ? "d-none" : "d-block close"}`}
        style={{ lineHeight: "1.8rem" }}
      >
        {breifPara}
      </p>
      <p
        className={`body-paragraph ${dropOpen ? "d-block open" : "d-none "}`}
        style={{ lineHeight: "1.8rem" }}
      >
        {actualPara}
      </p>
      <img
        src={
          dropOpen
            ? "/images/icons/text-card-arrow-open.svg"
            : "/images/icons/text-card-arrow-close.svg"
        }
        className="align-self-end my-3"
        style={{ cursor: "pointer" }}
        alt='dropOpen-icon'
      />
    </div>
  );
}

export default TextCardBox