import React from 'react'
import "./PdfList.css"
import PdfListBox from './PdfListBox'
import axios from "axios"

const pdfListcontent = [
    {
        para:"HIFEM procedure enhances the quality of life of elderly men with post-prostatectomy incontinence",
        pdf:"BTL_Emsella_DOC_Study-004_ENUS100.02"
    },
    {
        para:"The Use of HIFEM Technology in the Treatment of Pelvic Floor Muscles as a Cause of Female Sexual Dysfunction: A Multi-Center Pilot Study",
        pdf:"Emsella_-_Female_Sexual_Dysfunction"
    },
    {
        para:"Female urinary incontinence and sexual function after the HIFEM procedure",
        pdf:"BTL_Emsella_CLIN_Study-11-ASLMS2020_ENUS100"
    },
    {
        para:"HIFEM® TECHNOLOGY – The non-invasive treatment for urinary incontinence",
        pdf:"BTL_Emsella_DOC_Study-aslms_EN100_preview"
    },
    {
        para:"Safety and Efficacy of a Non‐Invasive High‐Intensity Focused Electromagnetic Field (HIFEM) Device for Treatment of Urinary Incontinence and Enhancement of Quality of Life",
        pdf:"Samuels_et_al-2019-Lasers_in_Surgery_and_Medicine"
    },
    {
        para:"A Comparative Study on the Effects of High-Intensity Focused Electromagnetic Technology and Electrostimulation for the Treatment of Pelvic Floor Muscles and Urinary Incontinence in Parous Women: Analysis of Posttreatment Data",
        pdf:"A_Comparative_Study_on_the_Effects_of.99436"
    },
    {
        para:"Electromyographic Evaluation of the Pelvic Muscles Activity After High-Intensity Focused Electromagnetic Procedure and Electrical Stimulation in Women With Pelvic Floor Dysfunction",
        pdf:"BTL_Emsella_SM_Electromyographic-Evaluation-of-the-Pelvic-Muscles-Activity-After-HIFEM-Procedure"
    },
    {
        para:"Long-term efficacy of HIFEM® procedure for treatment of urinary incontinence",
        pdf:"BTL_Emsella_CLIN_Study-10-ASLMS2020_ENUS100"
    },
    {
        para:"HIFEM® procedure and pelvic floor exercise for urinary incontinence",
        pdf:"BTL_Emsella_CLIN_Study-9-ASLMS2020_ENUS100"
    },
]



function PdfList() {
  return (
    <div className='pdf-list-container standard-padding-space'>
        <div className='container-width'>
            <div className='text-content text-center'>
                <p className='sub-heading'>RESEARCH</p>
                <h3 className='body-heading'>Stay Informed With Venus Health</h3>
                {/* <p className='body-paragraph'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but</p> */}
            </div>
            <div className='container-fluid mt-5'>
                <div className='row gy-4'>
                    {
                        pdfListcontent.map((ele)=>{
                            return(
                                <div className='col-lg-6'>
                                    <PdfListBox
                                        para={ele.para}
                                        pdf={ele.pdf}
                                    />
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default PdfList