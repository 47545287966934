import React, { useState } from "react";
import "./HeroBanner.css";
import Header from "../Header/Header";
import AppointmentModal from "../AppointmentModal/AppointmentModal"; // Import the AppointmentModal component
import { useNavigate } from "react-router-dom";

const HeroBanner = ({
  Heading,
  subHeading,
  spanHeading = "",
  Paragraph,
  btnText,
  bgImg,
  bgVideo = false,
  btn = false,
  btnRef = "/",
  btnRefJnap = false,
  socialLinks = false,
  serviceForm = false,
  bgEffect = false,
  homeHeading = false,
  appointmentBtn=true,
}) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div
      className={`common_hero_section ${bgVideo && "bg-video"} mb-5`}
      style={bgVideo ? {} : { backgroundImage: `url(${bgImg})` }}
    >
      {bgVideo && (
        <video autoPlay loop muted className="hero-bg-video">
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className={`${bgEffect && "bg-effect"} h-100`}>
        <div
          className={`hero-container h-100  px-0 `}
        >
          <div className="wrapper d-flex flex-column">
            <Header />
            <div
              className=" d-flex align-items-center py-5 my-auto"
              style={bgVideo ? {} : { minHeight: "30rem" }}
            >
              <div className="gy-2 w-100">
                <div
                  className={
                    serviceForm
                      ? "col-md-6 "
                      : "col-12 hero_content_wrapper px-3 w-100"
                  }
                >
                  <div className="hero-text-content w-100 text-center">
                    <h5 className="hero-sub-heading">{subHeading}</h5>

                    {homeHeading ? (
                      <h1 className="hero-section-heading cus-head-font my-3" style={{ fontSize: "28px" }}>
                        <span className="heading-color-change cus-head-font" style={{ fontSize: "30px", fontWeight: "900" }}>Strengthen</span> <>Your Pelvic Floor.</><br />
                        <span className="heading-color-change cus-head-font" style={{ fontSize: "30px", fontWeight: "900" }}>Regain Control</span> <>of Your Bladder.</><br />
                        <span className="heading-color-change cus-head-font" style={{ fontSize: "30px", fontWeight: "900" }}>Enhance</span> <>Your Sexual Performance.</>
                      </h1>
                    ) : (
                      <h1 className="hero-section-heading my-3">
                        {Heading}
                        <span className="heading-color-change">{spanHeading}</span>
                      </h1>
                    )}

                    <p className="body-paragraph mx-auto">{Paragraph}</p>

                    {
                      appointmentBtn &&
                    <button
                      className="openModalBtn hero-btn"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      BOOK AN APPOINTMENT
                    </button>
                    }

                    {modalOpen && <AppointmentModal setOpenModal={setModalOpen} />}
                    {socialLinks && (
                      <div className="social-links py-2">
                        <div className="first-row d-flex gap-3">
                          <div className="link-container d-flex align-items-center gap-1">
                            <img src="/images/icons/social-1.png" alt="social-icon" />
                            <p className="m-0">647-948-8750</p>
                          </div>
                          <div className="link-container d-flex align-items-center gap-1">
                            <img src="/images/icons/social-2.png" alt="social-icon" />
                            <p className="m-0">416-904-2013</p>
                          </div>
                        </div>
                        <div className="link-container d-flex align-items-center gap-1">
                          <img src="/images/icons/social-3.png" alt="social-icon" />
                          <p className="m-0">info@lotusaccounting.ca</p>
                        </div>
                        <div className="link-container d-flex align-items-center gap-1">
                          <img src="/images/icons/social-4.png" alt="social-icon" />
                          <p className="m-0">Suite 304-305 Milner Ave. Toronto, ON M1B 3V4</p>
                        </div>
                        <div className="link-container d-flex align-items-center gap-1">
                          <img src="/images/icons/social-5.png" alt="social-icon" />
                          <p className="m-0">Mon-Sat 10:00AM to 5:00PM Eastern Time</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default HeroBanner;
