import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import ServicesBookAppointment from '../component/ServicesBookAppointment/ServicesBookAppointment'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'

function Services() {
  return (
    <>
    <Helmet>
      <title>Pelvic Chair | Venus Wave Therapy Pelvic Floor Strengthening</title>
      <meta name='description' content='The Venus Wave Therapy treatment with Pelvic Chair Strengthens your Pelvic Floor muscle as it produces 11,000 perfect Kegels in just 28 minutes.' />
      <link rel="canonical" href="https://venushealthmedical.com/services/" />
    </Helmet>
    <HeroBanner 
      subHeading=""
      Heading="How It Works"
      nextLineSpanHeading=""
      Paragraph="Take charge of your pelvic health, regain your confidence, and enjoy a life free from the constraints of bladder leakage and sexual dysfunction."
      btn={false}
      bgImg="/images/creatives/service-hero-bg.png"
    />

<div className='left-gradient'>
    <TextContent
         heading= {<h2>Innovation That Can Stop Bladder Leaks</h2>}
         subHeading="For Women"
         content={"Did you know that sitting on our ‘Kegel Throne’ (approved by The FDA and Health Canada) can finally stop these problems with your leaky bladder? The “treatment chair” adapts to one’s back, fits the spine, and provides comfort. It uses High-Intensity Focused Electromagnetic Waves to do it’s magic."}
         postHeading='Is It Painful?'
         postContent='The treatment does not cause any discomfort. You can simply sit, relax, and enjoy your treatment.'
         btn={true}
         btnText="Learn More"
         btnRef="/forwomen"
         shiftRow={false}
         img="/images/creatives/women-text-2.png"
         reviewContent={false}
      />
</div>

<div className='right-gradient'>
    <ServicesBookAppointment/>

    <TextContent
         heading= "Treat Urinary Incontinence"
         subHeading="FOR MEN"
         postSubHeading="& Erectile Dysfunction"
         content={"Are you experiencing bladder leakage when you sneeze or exercise? Do you feel strong urges to urinate and feel like you won’t make it to the bathroom on time? Are you experiencing erectile dysfunction? These are all signs that you have an issue with your pelvic floor. \n When you have an issue with bladder leakage or other issues related to your pelvic floor, it can affect every aspect of your life. Some people even avoid certain activities for fear of embarrassment. This isn’t something you should simply accept, however. Get in touch with an Elev8te consultant for a free consultation."}
         btn={true}
         btnText="Learn more"
         btnRef="/how-it-works-for-men"
         shiftRow={true}
         img="/images/creatives/men-text-1.png"
      />
</div>
<div className='bottom-gradient'>

    <CardCarousel
      subHeading="NEWS & FEEDS"
      heading="Latest News"
    />


    <Testimonials/>
    <Footer/>
  </div>
  </>
  )
}

export default Services