import React from 'react';
import './PartnershipAwards.css';

const PartnershipAwards = () => {
    return (
        <div className='partnership_awards'>
            <div className='container'>
                <div className="flex-container">
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/logo-1.png" alt="partner company logo" /></div>
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/logo-2.png" alt="partner company logo" /></div>
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/logo-3.png" alt="partner company logo" /></div>
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/logo-4.png" alt="partner company logo" /></div>
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/logo-5.png" alt="partner company logo" /></div>
                </div>
            </div>
        </div>
    )
};

export default PartnershipAwards;