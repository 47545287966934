import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import ServicesText from '../component/ServicesText/ServicesText'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import VideoContent from '../component/VideoContent/VideoContent'
import { Helmet } from 'react-helmet'
import BgTextContent from '../component/BgTextContent/BgTextContent'

function Home() {
  return (
    <>
    <Helmet>
      <title>Pelvic Floor Clinic Toronto | Venus Wave Therapy</title>
      <meta name='description' content='Venus Wave Therapy is a Pelvic Floor Clinic in Toronto that offers a non-invasive treatment for bladder leakage in women.' />
      <link rel="canonical" href="https://venushealthmedical.com/" />
      <meta name="keywords" content="Pelvic Floor Clinic Toronto"></meta>
    </Helmet>
      <HeroBanner 
        subHeading=""
        Heading={""}
        homeHeading={true}
        nextLineSpanHeading=""
        Paragraph=""
        btnText="BOOK AN APPOINTMENT"
        bgImg="/images/creatives/home-bg.jpg"
        btn={true}
        // Remove btnRef and btnRefJnap
        bgEffect={true}
        bgVideo="/Home-Page-Banner-Video.mp4"
      />

      

    <div className='left-gradient'>
      <Sponsor/>
      <TextContent
         heading= "Our Commitment To You"
         subHeading="WELCOME TO VENUS WAVE THERAPY"
         content="We are committed to providing women who experience bladder leakage and/or sexual dysfunction with the safest, non-invasive, cutting edge, and long-lasting treatment. Our team is dedicated to providing exceptional, discreet care in a warm, caring and comfortable environment."
         btn={false}
         btnText=""
         shiftRow={true}
         img="/images/creatives/home-text-1.png"
         reviewContent={true}
         review={"The overall satisfaction with the services provided by Venus Wave Theraphy"}
         reviewUser={""}
         userImg={"/images/creatives/review-user.png"}
      />
</div>

<div className='right-gradient'>
      <ServicesText/>

      <TextContent
         heading= "Pelvic Floor Clinic Milton & Etobicoke/North York"
         subHeading="NO PAIN. NO NEEDLES. NO SIDE EFFECTS. ALL BENEFIT."
         content="Venus Wave Therapy offers a non-invasive treatment for bladder leakage in women. 17% of women over the age of 18 experience bladder leakage during minor movements such as coughing, sneezing, or laughing. One treatment session at Venus Wave Therapy is equivalent to 11,000 Kegel exercises, with a result of strengthening the muscles of your pelvic floor."
         btn={true}
         btnText="LEARN MORE"
         btnRef="/services"
         shiftRow={false}
         img="/images/creatives/home-text-2.png"
         reviewContent={false}
      />
</div>
      <BgTextContent/>
      <VideoContent
            heading="Pain-Free Comfort You Deserve"
            subHeading="Pelvic Chair For Incontinence"
            content="Worried about discomfort during the treatment? Rest assured, our Pelvic Chair treatment is entirely pain-free. Simply sit back, relax, and allow the chair to work its wonders while you enjoy a comfortable and soothing session. Take the first step towards regaining control over your bladder, enhancing your sexual pleasure, and enjoying a life free from the limitations of urinary incontinence."
            btn={true}
            shiftRow={true}
            btnText="Free Assesment"
            // btnRef="/contact"
            btnRefTel={true}
            img="/images/creatives/video-thumbnail-image-home.png"
            reviewContent={false}
            videoSrc={"https://www.youtube.com/embed/O7Mjuv1mWXc?si=cyLtbV4ijSeDheuf&autoplay=1" }
        />
<div className='bottom-gradient'>
      <CardCarousel
        subHeading="NEWS & FEEDS"
        heading="Latest News"
      />

      <Testimonials/>

      <Footer/>
</div>
    </>
  )
}

export default Home
