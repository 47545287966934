import React from 'react'

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}

function PopularBlogCard({
    img,
    title,
    date,
    anchor,
    redirectionFunction
}) 
{
const breifTitle = truncateText(title,50)
  return (
    <div className='popular-card d-flex flex-column flex-xl-row gap-2 align-items-center my-5 ' onClick={()=>{redirectionFunction(anchor)}}>
        <img src={img} className='popular-blog-img mx-2' alt='popular-blog-img'/>
        <div className='blog-details mx-md-0 mx-5'>
            <h5 className='card-title font-bebas'>{breifTitle}</h5>
            <p className='card-date'>{date}</p>
        </div>
    </div>
  )
}

export default PopularBlogCard