import React,{useState} from 'react'
import "./ScrollingTextContent.css"
import { useNavigate } from 'react-router-dom'
import AppointmentModal from '../AppointmentModal/AppointmentModal';

function ScrollingTextContent({
    shiftRow=false,
    heading="",
    subHeading="",
    content="",
    listContent=false,
    img,
    btn=false,
    btnText="Read More",
    btnRef="/",
    btnYellow=false

}) {
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph mb-3'>{paragraph}</p>);
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className='ScrollingTextContent-container standard-padding-space mb-4' >
        <div className='container'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-center gy-4`}>
                <div className='col-md-6 h-100 my-auto px-4'>
                    <img src={ img} className='img-fluid' alt='img'/>
                </div>
                <div className='col-md-6'>
                    <div className='text-content d-flex flex-column align-items-start justify-content-center gap-lg-2 h-100 px-2 px-md-0'>
                        {subHeading == "" ? <></> :<h5 className='sub-title'>{ subHeading}</h5>}
                        <h3 className='heading body-heading'>{ heading}</h3>
                        <div className='scroll-content'>
                            <p className='content body-paragraph'>
                                {paragraphs}
                            </p>
                            <ul>
                                {listContent && 
                                    <>
                                        {listContent.map((ele)=>{
                                            return <li><p className='body-paragraph'>{ele}</p></li>
                                        })}
                                    </>
                                }
                            </ul>
                        </div>
                        {/* <p className='content body-paragraph'>{ paragraphs}</p> */}
                        {btn ?
                           btnRef == "jnape"
                            ?
                            <a 
                                href='https://mexphysio.janeapp.com/#/staff_member/4' 
                                target='blank'
                                style={{textDecoration:"none"}}
                            >
                                <button className={`text-btn ${btnYellow && "btn-y"} mt-4`}  style={{textTransform:"uppercase"}}>{btnText}</button>
                            </a>
                            :
                            <>
                                {btn && 
                                <>
                                <button className='text-btn mt-2' onClick={()=>{setModalOpen(true);}}>{btnText}</button>
                                {modalOpen && <AppointmentModal setOpenModal={setModalOpen} ModalHeading={btnText}/>}
                                </>}
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ScrollingTextContent
