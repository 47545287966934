import React,{useState} from 'react'
import "./WomenBookAppointment.css"
import AppointmentModal from '../AppointmentModal/AppointmentModal'

function WomenBookAppointment() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className='women-appointment-container standard-margin-space py-4'>
        <div className='text-content standard-padding-space text-center w-75 mx-auto'>
          <h3 className='title my-3 mb-4'>Pelvic Chair Treatment</h3>
          <p className='body-paragraph' style={{color:"white"}}>Here at Elev8te we will formulate a treatment plan tailored to your specific needs and condition(s). You should look forward to drastic positive changes in your confidence and lifestyle after completing our program…. Everyone deserves to feel that freedom!</p>
          
          <button className="content-btn mt-2" onClick={()=>{setModalOpen(true);}}>BOOK AN APPOINTMENT</button>
          {modalOpen && <AppointmentModal setOpenModal={setModalOpen} />}
        </div>
    </div>
  )
}

export default WomenBookAppointment 