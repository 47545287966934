import React,{useState} from 'react'
import "./MenBookAppointment.css"
import { useNavigate } from 'react-router-dom'
import AppointmentModal from '../AppointmentModal/AppointmentModal'

function MenBookAppointment() {
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className='men-appointment-container standard-margin-space '>
        <div className='text-content standard-padding-space text-center w-75 mx-auto my-4'>
          <h3 className='title my-3 mb-4'>Elev8te Men’s Therapy </h3>
          <p className='body-paragraph' style={{color:"white"}}>Here at Elev8te we will formulate a treatment plan tailored to your specific needs and condition(s). You should look forward to drastic positive changes in your confidence and lifestyle after completing our program…. Everyone deserves to feel that freedom!</p>
          
         <button className="content-btn mt-2" onClick={()=>{setModalOpen(true);}}>BOOK AN APPOINTMENT</button>
         {modalOpen && <AppointmentModal setOpenModal={setModalOpen} />}
            <div className='down-content mt-5'>
                <h4 className='small-title'>Are you suffering from erectile disfunction?</h4>
                <p className='body-paragraph' style={{color:"white"}}>Using similar therapeutic technology our partner clinic, Elev8te Men’s Health, can help.</p>
                <a 
                  href={`https://elev8temenshealth.com`} 
                  target="_blank"
                >
                <button className="content-btn mt-2" >Learn More</button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default MenBookAppointment