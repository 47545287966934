import React, { Fragment, useEffect, useState } from "react";
import HeroBanner from "../component/Common Banner Component/HeroBanner";
import Footer from "../component/Footer/Footer";
import IdvBlogSec from "../component/IdvBlogSec/IdvBlogSec";
import { useParams } from "react-router";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

const IdvBlog = () => {
  const [blogsData, setBlogsData] = useState();

  const [loading, setLoading] = useState(true);

  // const { id } = useParams();
  // console.log(id);

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async (slug) => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let blog = res.data.data.find((item) => item.slug_url == slug);
          setBlogsData(blog);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {

    const currentPath = window.location.pathname;
    let pathId = currentPath.substring(1)
    if (pathId.endsWith('/')) {
      pathId = pathId.slice(0, -1);
    }
    getAllBlogs(pathId);
  }, []);


  return (
    <>
      <Fragment>
        {loading && !blogsData ? (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "#85329A", width: "120px", height: "120px" }}
            />
          </div>
        ) : (
          blogsData && (
            <>
            <Helmet>
              <title>{blogsData.metaTitle}</title>
              {blogsData.metaDescription && <meta name='description' content={blogsData.metaDescription} />}
              {blogsData.canonicalLink && <link rel="canonical" href={blogsData.canonicalLink}></link>}
            </Helmet>
              
               <HeroBanner 
                  subHeading=""
                  Heading={blogsData.title}
                  nextLineSpanHeading=""
                  Paragraph=""
                  btn={false}
                  bgImg="/images/creatives/blog-hero-bg.jpg"
                />
              <IdvBlogSec
                blogsData={blogsData.blogs_content}
                datePublished={blogsData.published_date}
                text={blogsData.brief_paragraph}
              />
              <Footer/>
            </>
          )
        )}
      </Fragment>
    </>
  );
};

export default IdvBlog;
