import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from "../component/Footer/Footer"
import { Helmet } from 'react-helmet'
import ScrollingTextContent  from '../component/ScrollingTextContent/ScrollingTextContent'
import BgTextContent from '../component/BgTextContent/BgTextContent'

function About() {
  return (
    <>
  <Helmet>
    <title>Bladder Leakage Treatment Toronto | Venus Treatment</title>
    <meta name='description' content='Venus Wave Therapy provides bladder leakage treatment to women who experience bladder leakage, urinary incontinence or sexual dysfunction.' />
    <link rel="canonical" href="https://venushealthmedical.com/about/" />
    <meta name="keywords" content="About Us"></meta>
  </Helmet>
      <HeroBanner 
        subHeading=""
        Heading="About Us"
        nextLineSpanHeading=""
        Paragraph="Discover the innovation and expertise behind Venus Health for best solutions for your pelvic health and problems. Your journey to renewed confidence begins with us!"
        bgImg="/images/creatives/about-hero-bg.png"
        btn={true}
        btnText="BOOK AN APPOINTMENT"
        btnRef="/contact-milton"
        btnRefJnap={true}
      />
  <div className='right-gradient'>
<div className='left-gradient'>
      <TextContent
         heading={<h2>Our Commitment To You</h2>}
         subHeading="WELCOME TO VENUS WAVE THERAPY"
         content="We are committed to providing women who experience bladder leakage and/or sexual dysfunction with the safest, non-invasive, cutting edge, and long-lasting treatment. Our team is dedicated to providing exceptional, discreet care in a warm, caring and comfortable environment. We are trained to answer all of your questions and concerns during a thorough, no-charge consultation. Our experienced team at Elev8te’s Venus Wave Therapy Clinics specialize in evaluating each client’s individual needs based upon reported symptoms during their initial and ongoing evaluation."
         btn={true}
         shiftRow={true}
         btnText="Free Assesment"
         btnRef="/"
         btnRefTel={true}
         img="/images/creatives/about-text-1.png"
         reviewContent={false}
      />
      <BgTextContent/>
</div>
      <TextContent
         heading= "Run By Women... For Women (and men too!)"
         subHeading="DISCREET PROFESSIONAL CARE IN A WARM, COMFORTABLE SETTING."
         content={"Venus Wave Therapy Milton & Etobicoke/North York provides the safest and most effective bladder leakage treatment option for women experiencing bladder leakage, Pelvic Floor Weakness, and Sexual Dysfunction.\n Venus Wave Therapy is high-intensity focused electromagnetic wave therapy used to stimulate and strengthen the muscles of the pelvic floor. In one (FULLY CLOTHED) session you receive 11,000 supramaximal muscle contractions (known as KEGELS), in just 28 minutes."}
         btn={true}
         btnText="Free Assesment"
         btnRef="/"
         btnRefTel={true}
         shiftRow={false}
         img="/images/creatives/about-text-2.png"
         reviewContent={false}
      />

      <Sponsor/>
</div>
<div className='right-gradient'>

      <ScrollingTextContent
         shiftRow={false}
         heading="Get to Know Your Clinician Gayathri, PT"
         subHeading=""
         content={"Gayathri, affectionately known as Gaaya, embarked on her professional journey by obtaining her Bachelor of Physiotherapy in 2004. Building upon this foundation, she pursued further studies at Massey University in New Zealand, graduating in 2009 with a Post Graduate degree in Health Science, specializing in Sports and Exercise Science.\nWith a Level 3 Advanced Pelvic Health Physiotherapist certification in 2018 she has given excellent results in multitude of her clients ranging from pregnancy related pelvic pain to chronic pelvic pain/bladder incontinence and sexual dysfunction. Accreditation as a McMaster University-certified Medical Acupuncture Provider, Gaaya stands as a prominent figure in her field. Notably, she is among the select few G.L.A.D – Arthritis Therapists adept at assisting clients with hip and knee arthritis. Gaaya's expertise extends to encompassing the McKenzie Techniques for spine care and the Mulligan concepts of joint and spine mobilizations.\nGaaya's professional journey spans over two decade and she harbors a profound passion for yoga and holds a prestigious Gold membership (CYA- RYT- 500) from the Canadian Yoga Alliance.\nOutside of her professional pursuits, Gaaya finds joy in dancing, cooking, and leading an active lifestyle alongside her young family."}
         img="/images/creatives/about-text-4.png"
         btn={true}
         btnText="Book Appointment"
         btnRef='jnape'
      
      />

      {/* <ScrollingTextContent
         shiftRow={true}
         heading="Get to Know Your Clinician Garima, PT"
         subHeading=""
         content={"Garima is a Pelvic Floor Therapist registered with the College of Physiotherapists of Ontario. She specializes in personalized therapeutic approaches with a dedication to promoting pelvic health for individuals. Garima wishes to empower her patients to achieve optimal well-being and enhance their quality of life.\n Pelvic floor physical therapy (PFPT) is a specialty where the physiotherapist focuses on the rehabilitation of muscles in the pelvic floor after injury or dysfunction. Medical conditions that can be treated include:"}
         img="/images/creatives/about-text-5.webp"
         btn={true}
         btnText="Book Appointment"
         btnRefTel={true}
         listContent={["Muscle weakness or tightness post-childbirth.","Constipation.","Fecal or urinary incontinence.","Pelvic organ prolapse."]}
      />
      <TextContent
         heading= "Get to Know Your Clinician Joshua Portugues, RN"
         subHeading=""
         content={"Registered with the College of Nurses of Ontario since 2011, Josh started off his career at St Joseph’s Healthcare Hamilton. Seeking a new challenge that aligned with his interests, he ventured into the world of Men’s Sexual Health as a nurse clinician at a high-volume Erectile Dysfunction Clinic in Toronto.\n Wanting to expand services to the opposite sex, Venus Health Medical was conceptualized to help women with incontinence of all types in Milton, the greater Toronto Area and beyond. He has a particular intertest in optimizing the lifestyle factors of each client to elicit the best results from the clinic’s treatments. When not in clinic, Josh continues to practice bedside nursing at St. Joseph’s Healthcare Hamilton, in the Medical Step Down/Coronary Care Unit. He calls Milton home with his wife and his twins Kylie and Kaleb."}
         btn={true}
         btnText="Book Appointment"
         btnRef="/"
         btnRefTel={true}
         shiftRow={false}
         img="/images/creatives/about-text-3.png"
         reviewContent={false}
      /> */}

</div>
<div className='bottom-gradient'>
      <CardCarousel
        subHeading="NEWS & FEEDS"
        heading="Latest News"
      />

      <Testimonials/>

      <Footer/>
</div>
    </>
  )
}

export default About