import React from 'react'
import "./ServicesBookAppointment.css"
import { useNavigate } from 'react-router-dom'

function ServicesBookAppointment() {
  const navigate = useNavigate()
  return (
    <div className='services-book-appointment-container standard-margin-space'>
         <div className='text-content  text-center w-75 mx-auto'>
          <h3 className='title my-3 mb-4'>Eliminate Urinary Incontinence...<br/> Bladder Leakage Treatment That Works!</h3>
          <p className='body-paragraph' style={{color:"white"}}>Improve your sexual function:</p>
          <p className='body-paragraph' style={{color:"white"}}>Venus Wave Therapy stimulates motor nerves and increases circulation in the vaginal canal.</p> 
          <p className='body-paragraph' style={{color:"white"}}>Increased sensation + increased lubrication = Greater pleasure and more sexual enjoyment!</p>
          <button className="content-btn mt-4" onClick={()=>{navigate("/contact-milton")}}>CONTACT US</button>
        </div>
    </div>
  )
}

export default ServicesBookAppointment