import "./Testimonials.css"
import React,{useRef,useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import TestimonialCard from "./TestimonialCard";

function Testimonials({
  mensData = false
}) {
  const swiperRef = useRef(null);
  const [mouseHoverBtn,setMouseHoverBtn] = useState(false)
  let blogData = [
    {clientName:"Ranold R.",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"After struggling with urinary incontinence for years, I decided to try Venus Health. The Pelvic Chair treatment worked wonders for me! I regained control over my bladder, and the improvement in my confidence is beyond words."},
    {clientName:"Amelia	E.",clientImg:"/images/icons/profile-icon-female.svg",clientReview:"The team at Venus Health made me feel comfortable and supported throughout my treatment. Their expertise and dedication to my well-being were evident at every step. I highly recommend Venus Health for anyone facing pelvic health challenges."},
    {clientName:"Corry W. ",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"The Venus Wave Therapy not only addressed my bladder leakage but also enhanced my sexual pleasure. It's an incredible feeling to regain control over my body. Thank you, Venus Health, for giving me the freedom I deserve!"},
    
    {clientName:"Ranold R.",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"After struggling with urinary incontinence for years, I decided to try Venus Health. The Pelvic Chair treatment worked wonders for me! I regained control over my bladder, and the improvement in my confidence is beyond words."},
    {clientName:"Amelia	E.",clientImg:"/images/icons/profile-icon-female.svg",clientReview:"The team at Venus Health made me feel comfortable and supported throughout my treatment. Their expertise and dedication to my well-being were evident at every step. I highly recommend Venus Health for anyone facing pelvic health challenges."},
    {clientName:"Corry W.",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"The Venus Wave Therapy not only addressed my bladder leakage but also enhanced my sexual pleasure. It's an incredible feeling to regain control over my body. Thank you, Venus Health, for giving me the freedom I deserve!"},
  ]

  if(mensData)
  {
    blogData = [
      {clientName:"Ranold R.",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"After struggling with urinary incontinence for years, I decided to try Venus Health. The Pelvic Chair treatment worked wonders for me! I regained control over my bladder, and the improvement in my confidence is beyond words."},
      
      {clientName:"Corry W. ",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"The Venus Wave Therapy not only addressed my bladder leakage but also enhanced my sexual pleasure. It's an incredible feeling to regain control over my body. Thank you, Venus Health, for giving me the freedom I deserve!"},
      
      {clientName:"Ranold R.",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"After struggling with urinary incontinence for years, I decided to try Venus Health. The Pelvic Chair treatment worked wonders for me! I regained control over my bladder, and the improvement in my confidence is beyond words."},
      
      {clientName:"Corry W.",clientImg:"/images/icons/profile-icon-male.svg",clientReview:"The Venus Wave Therapy not only addressed my bladder leakage but also enhanced my sexual pleasure. It's an incredible feeling to regain control over my body. Thank you, Venus Health, for giving me the freedom I deserve!"},
    ]
  }

  const handlePrevButtonClick = () => {
    const swiperInstance = document.querySelector('#testimonial-cards-slider').swiper;
    swiperInstance.slidePrev();
  };
  
  const handleNextButtonClick = () => {
    const swiperInstance = document.querySelector('#testimonial-cards-slider').swiper;
    swiperInstance.slideNext();
  };

  return (
    <div className="Testimonials-container standard-padding-space">
      <div className="w-100 container-fluid px-0">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <p className="sub-heading mb-2">testimonials</p>
        <h3 className="body-heading">Lovely people talk about us</h3>
        <p className="body-paragraph mx-auto py-1 px-3 px-md-2" style={{maxWidth:"900px",lineHeight:"33px"}}>Want to know about our patient’s experience?<br></br> These testimonials are just a glimpse into the success stories we witness every day.</p>
        <Swiper
        spaceBetween={0}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop={true}
        autoplay={
          {
            delay:2000,
            disableOnInteraction:false
          }
        }
        modules={[Autoplay]}
        pagination={{ clickable: true }}
        observer ={true}
        observeParents={true}
        parallax={true}
        breakpoints={{
            280: {
              slidesPerView: 1,
            },
            1450: {
              slidesPerView: 3,
            }
          }}
        className="testimonials-list py-5 mt-2"
        id="testimonial-cards-slider"
            >
              {
                blogData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind} className="pt-5 px-3 px-sm-0">
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview}/>
                    </SwiperSlide>
                     </>)
                })
              }
        </Swiper>
        <div className="btn-container mt-5">
          <button className="swiper-button-prev swiper-button active" onClick={handlePrevButtonClick}><img src="/images/icons/btn-arrow-icon.svg" alt="arrow icon" className="btn-arrow-icon mb-0"/></button>
          <button className="swiper-button-next swiper-button" onClick={handleNextButtonClick} onMouseOver={()=>{setMouseHoverBtn(true)}} onMouseOut={()=>{setMouseHoverBtn(false)}}><img src="/images/icons/btn-arrow-icon.svg" alt="arrow icon" className={`btn-arrow-icon ${mouseHoverBtn ? "btn-arrow-icon-left-rotate" : "btn-arrow-icon-left"} mb-0`}/></button>
        </div>
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials