import React, { useState,useRef,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({
  darkBg=false
}) => {
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenContact, setIsDropdownOpenContact] = useState(false);
  const [isDropdownOpenContactDesk, setIsDropdownOpenContactDesk] = useState(false);
  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();
  const dropdownRefContact = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
      if (dropdownRefContact.current && !dropdownRefContact.current.contains(event.target) && isDropdownOpenContactDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenContactDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk,isDropdownOpenContactDesk]);


const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/services");
        clickCountRef.current = 0;
      }
  };

  const onClickContact = (e) => {
    e.preventDefault();
    
    setIsDropdownOpenContact((prev)=>!prev)
     
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  return (
    <div className={`header mt-3`} style={darkBg?{backgroundColor:"#6a6a6a"}:{}}>
      <div className="header-wrapper">
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container px-lg-0 px-2 py-2">
            <div className="nav-elements d-flex justify-content-between align-items-center px-lg-2">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src="/images/icons/nav-logo.png" alt="logo" className="nav-logo" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/>
              <nav>
                <ul
                  className="nav-list d-lg-flex gap-lg-3 gap-xl-4 align-items-center d-none main-nav"
                  id="js-menu"
                >
                  <li>
                    <Link to={"/home"} className="nav-links font-lato">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"} className="nav-links font-lato">
                      ABOUT
                    </Link>
                  </li>

                  <div className="navigation-dropdown" ref={dropdownRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center">
                          <Link to={"/services"} className="nav-links font-lato" onClick={()=>{setIsDropdownOpenDesk(false)}}>SERVICES</Link>
                          <img src='/images/icons/drop-icon.svg' alt="arrow icon" className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
              
                        <Link to={"/forwomen"} className="nav-links font-lato" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">FOR WOMEN</p>
                        </Link>
                        <Link to={"/how-it-works-for-men"} className="nav-links font-lato" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">FOR MEN</p>
                        </Link>
                      </div>
                    </li>
                    </div>



                  <li>
                    <Link to={"/benefits"} className="nav-links font-lato">
                      BENEFITS
                    </Link>
                  </li>
                  
                  <li>
                    <Link to={"/blog"} className="nav-links font-lato">
                      BLOGS
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={"/contact"} className="nav-links font-lato">
                    CONTACT US
                    </Link>
                  </li> */}
                  <div className="navigation-dropdown" ref={dropdownRefContact}>
                  <li onMouseEnter={()=>{setIsDropdownOpenContactDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center">
                          <Link className="nav-links font-lato" onClick={()=>{setIsDropdownOpenContactDesk((prev)=> !prev)}}>CONTACT US</Link>
                          <img src='/images/icons/drop-icon.svg' alt="arrow icon" className={isDropdownOpenContactDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenContactDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenContactDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenContactDesk(false)}}>
              
                        <Link to={"/contact-milton"} className="nav-links font-lato" onClick={()=>{setIsDropdownOpenContactDesk(false)}}>
                          <p className="mb-0">Milton - Mex Physio</p>
                        </Link>
                        {/* <Link to={"/contact-north-york"} className="nav-links font-lato" onClick={()=>{setIsDropdownOpenContactDesk(false)}}>
                          <p className="mb-0">North York - True Life Wellness</p>
                        </Link> */}
                      </div>
                    </li>
                    </div>
                </ul>
              </nav>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src="/images/icons/icons8-hamburger-menu-50.png"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
              </label>

              <div className="nav-buttton ml-5 d-lg-flex d-none gap-4 extras-btns">
                <a href="tel:(905) 636-6121">
                  <button className="genral-btn font-lato" style={{fontSize:"15px",fontWeight:"bold",padding: "10px 15px",textTransform:"uppercase"}} >
                  <img src="/images/icons/phone-icon-header-btn.svg" alt="phone icon" style={{width:"15px",height:"15px",marginRight:"5px",marginBottom:"3px"}}/>
                  (905) 636-6121
                  </button>
                </a>
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <div className="py-3 d-lg-none" style={{ display: menuState ? "block" : "none" }}>
            <ul
              className="resp-nav-list d-lg-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/home"} className="nav-links font-lato">
                  HOME
                </Link>
              </li>
              <li>
                <Link to={"/about"} className="nav-links font-lato">
                  ABOUT
                </Link>
              </li>
               <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                      <a className="nav-links font-lato" >SERVICES</a>
                      <img src='/images/icons/drop-icon-b.png' alt="arrow-icon" className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    <Link to={"/forwomen"} className="nav-links font-lato" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0">FOR WOMEN</p>
                    </Link>
                    <Link to={"/how-it-works-for-men"} className="nav-links font-lato" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0">FOR MEN</p>
                    </Link>
                  </div>
                </div>
                </li>
              <li>
                <Link to={"/benefits"} className="nav-links font-lato">
                BENEFITS
                </Link>
              </li>
              <li>
                <Link to={"/blog"} className="nav-links font-lato">
                BLOGS
                </Link>
              </li>
              {/* <li>
                <Link to={"/contact"} className="nav-links font-lato">
                CONTACT US
                </Link>
              </li> */}
              <li>
              <div className="navigation-dropdown">
                <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickContact} >
                      <a className="nav-links nav-txt" >Contact Us</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpenContact ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpenContact ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                  <Link to={"/contact-milton"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenContact(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Milton - Mex Physio</p>
                  </Link>
                  {/* <Link to={"/contact-north-york"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenContact(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>North York - True Life Wellness</p>
                  </Link> */}
                  </div>
                </div>
              </li>
            </ul>
            <div className="nav-buttton flex-wrap d-flex gap-4 extras-btns">
              <a href="tel:(905) 636-6121">
                <button className="genral-btn font-lato" >
                  <img src="/images/icons/phone-icon-header-btn.svg" alt="phone icon" style={{width:"15px",height:"15px",marginRight:"5px",marginBottom:"3px",textTransform:"uppercase"}}/>
                  (905) 636-6121
                </button>
              </a>
            </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
  );
};

export default Header; 