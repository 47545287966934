import React,{useState} from 'react'
import "./TextCard.css"
import TextCardBox from './TextCardBox'

const cardContent = [
    {
        title:"Restored Muscle Control",
        iconImg:"/images/icons/text-card-1.svg",
        breifPara:"Just like any other muscles in your body, with age, the pelvic muscles weaken. Our Venus Wave Therapy works to stimulate the pelvic floor",
        actualPara:"Just like any other muscles in your body, with age, the pelvic muscles weaken. Our Venus Wave Therapy works to stimulate the pelvic floor muscles, strengthening and restoring them. One 28 minute treatment session is equivalent to 11,000 Kegel exercises!"
    },
    {
        title:"Stimulated Pelvic Floor",
        iconImg:"/images/icons/text-card-2.svg",
        breifPara:"Pelvic Floor Stimulation is a non-invasive, nonsurgical treatment to strengthen the pelvic floor in women, resulting in fewer leaks and stronger",
        actualPara:"Pelvic Floor Stimulation is a non-invasive, nonsurgical treatment to strengthen the pelvic floor in women, resulting in fewer leaks and stronger bladder control. Many women notice improvements in as little as their first session at Venus Wave Therapy."
    },
    {
        title:"Improved Bladder Control",
        iconImg:"/images/icons/text-card-3.svg",
        breifPara:"Studies estimate that 17% of women over the age of 18 experience urinary leaks. If you’re experiencing leakage when sneezing, coughing, laughing,",
        actualPara:"Studies estimate that 17% of women over the age of 18 experience urinary leaks. If you’re experiencing leakage when sneezing, coughing, laughing, or are simply having trouble making it to the bathroom on time, Venus Wave Therapy gives you back the control of your bladder."
    },
    {
        title:"Improved Sexual Function",
        iconImg:"/images/icons/text-card-4.svg",
        breifPara:"When the pelvic floor is weakened as a result of age, menopause, or after childbirth, it can result in reduced sexual arousal and infrequent orgasms.",
        actualPara:"When the pelvic floor is weakened as a result of age, menopause, or after childbirth, it can result in reduced sexual arousal and infrequent orgasms. Venus Wave Therapy strengthens your pelvic floor, stimulates the motor nerves in the vaginal canal, and improves circulation to your pelvic floor and vaginal canal. All resulting in increased sensation and increased lubrication of the area."
    },
]

function TextCard() {
  return (
    <div className='text-card-container standard-padding-space'>
        <div className='container-width '>
            <div className='text-content text-center'>
                <p className='sub-heading'>NO PAIN. NO NEEDLES. NO SIDE EFFECTS. ALL BENEFIT.</p>
                <h3 className='body-heading'>What Are The Venus Wave Therapy Benefits?</h3>
                <p className='body-paragraph'>The benefits are countless from Venus Wave Therapy. Our goal at Venus Wave Therapy is to get you back to feeling like your best self in just a few convenient sessions. If you’re experiencing consistent bladder leakage, our treatment is effective in strengthening your pelvic floor and giving you control of your bladder once again.</p>
            </div>
            <div className='container-fluid mt-5'>
                <div className='row gy-3'>
                    {
                        cardContent.map((ele)=>{
                            return(
                            <div className='col-lg-6'>
                                <TextCardBox
                                    title={ele.title}
                                    iconImg={ele.iconImg}
                                    breifPara={ele.breifPara}
                                    actualPara={ele.actualPara}
                                />
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextCard