import React,{useState} from 'react'
import "./BookAppointment.css"
import { useNavigate } from 'react-router-dom'
import AppointmentModal from '../AppointmentModal/AppointmentModal';

function BookAppointment({
    title="",
    content=""
}) 
{
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className='book-appointment-container standard-margin-space py-4'>
        <div className='text-content standard-padding-space text-center w-75 mx-auto'>
          <h3 className='title my-3 mb-4'>{title}</h3>
          <p className='body-paragraph' style={{color:"white"}}>{content}</p>
          
          <button className="content-btn mt-2" onClick={()=>{setModalOpen((curr)=> !curr)}}>BOOK AN APPOINTMENT</button>
          {modalOpen && <AppointmentModal setOpenModal={setModalOpen} />}
          
        </div>
    </div>
  )
}

export default BookAppointment