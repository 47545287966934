import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'

import PopularBlogs from "../component/PopularBlogs/PopularBlogs";


function blog() {
  return (
    <>
      <Helmet>
        <title>Blog - Venus Wave Therapy</title>
        <meta name='description' content='Keep yourself updated with our latest blog collection available at Venus Wave Therapy Medical website. Book your appointment and visit us today.' />
        <link rel="canonical" href="https://venushealthmedical.com/blog/" />
        <meta name="keywords" content="Blogs"></meta>
      </Helmet>
      <HeroBanner 
        subHeading=""
        Heading="Blog"
        nextLineSpanHeading=""
        Paragraph=""
        btn={false}
        btnText=""
        bgImg="/images/creatives/blog-hero-bg.jpg"
      />
      <PopularBlogs/>
      
      <Footer/>
    </>
  );
}

export default blog;
