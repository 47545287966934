import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from "../component/Footer/Footer"
import TextCard from '../component/TextCard/TextCard'
import BookAppointment from '../component/BookAppointment/BookAppointment'
import PdfList from '../component/PdfList/PdfList'
import VideoContent from "../component/VideoContent/VideoContent"
import { Helmet } from 'react-helmet'

function Benifits() {
  return (
    <>
    <Helmet>
      <title>Venus Wave Therapy Benefits | Benefits of Pelvic Floor Strengthening</title>
      <meta name='description' content='Venus Wave Therapy Benefits are non-surgical treatments that restore muscle control, improves bladder control, and improves sexual function.' />
      <link rel="canonical" href="https://venushealthmedical.com/benefits/" />
      <meta name="keywords" content="Venus Wave Therapy Benefits"></meta>
    </Helmet>
      <HeroBanner
        subHeading=""
        Heading="Venus Wave Therapy Benefits"
        nextLineSpanHeading=""
        Paragraph="Our Venus Wave Therapy is designed to provide individuals experiencing bladder leakage and sexual dysfunction with a safe, non-invasive, and highly effective treatment."
        bgImg="/images/creatives/benefit-hero-bg.jpg"
        btn={true}
        btnText="BOOK AN APPOINTMENT"
        btnRefJnap={true}
        />
      <div className="left-gradient">
        <TextCard/>
    </div>
    <div className="right-gradient">

        <VideoContent
            heading="No Pain. All Gain"
            subHeading="Non-Invasive and Comfortable"
            content="Venus Wave Therapy is a non-invasive treatment that allows you to address pelvic health concerns without discomfort. With advanced electromagnetic wave energy, you can experience the benefits in a relaxed and pain-free setting."
            btn={true}
            shiftRow={true}
            btnText="Free Assesment"
            btnRefTel={true}
            img="/images/creatives/video-thumbnail-image.png"
            reviewContent={false}
            videoSrc={"https://www.youtube.com/embed/I--aL7yS640?si=b6oz6GNSFRhCyXco&autoplay=1"}
        />
        <BookAppointment
            title='Treatment'
            content='Here at Elev8te we will formulate a treatment plan tailored to your specific needs and condition(s). You should look forward to drastic positive changes in your confidence and lifestyle after completing our program…. Everyone deserves to feel that freedom!'
        />
      </div>
      <div className="right-gradient">
        <PdfList/>
      </div>
      <div className="bottom-gradient">
        <Footer />
      </div>
    </>
  );
}

export default Benifits