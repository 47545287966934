import React,{useState,useEffect} from 'react';
import axios from 'axios';
import './App.css';
import "./bootstrap.min.css"
import Home from './screens/Home';
import About from './screens/About';
import ForMen from './screens/ForMen';
import Footer from "./component/Footer/Footer"
import { Routes, Route,Navigate } from 'react-router-dom';
import ForWomen from './screens/ForWomen';
import Services from './screens/Services';
import Contact from "./screens/Contact"
import Benifits from './screens/Benifits';
import Blog from './screens/blog';
import IdvBlog from "./screens/IdvBlog"

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"

import ThankYou from "./screens/ThankYou"
import NotFound from "./screens/NotFound"
import PrivacyPolicy from './screens/PrivacyPolicy';
import ContactMilton from './screens/ContactMilton';
import ContactNY from './screens/ContactNY';


function App() {
  const [blogRoute , setBlogRoute] = useState(null)

  useEffect(()=>{
    axios.get("/blogs-id")
    .then((res)=>{
        if(res.data.status === "success")
        {
          setBlogRoute(res.data.data)
        }
    })
    .catch((err)=>{
      console.log("eror =>",err);
    })
  },[])
  return (
    <div style={{backgroundColor:"white"}}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to={"/"}/>} />
        <Route path="/about" element={<About />} />
        <Route path="/physiotherapy" element={<Home />} />
        <Route path="/research" element={<Home />} />
        <Route path="/how-it-works-for-men" element={<ForMen />} />
        <Route path="/forwomen" element={<ForWomen />} />
        <Route path="/services" element={<Services />} />
        {/* <Route path="/contact" element={<Contact/>} /> */}
        <Route path="/blog" element={<Blog />} />

        <Route path="/contact-milton" element={<ContactMilton/>} />
        {/* <Route path="/contact-north-york" element={<ContactNY/>} /> */}
        {
          blogRoute && 
            blogRoute.map((ele)=>{
              return(<Route path={`/${ele}`} element={<IdvBlog />} />)
            })
          }
          {/* return(<Route path={`/blogs/:id`} element={<IdvBlog />} />) */}
        <Route path="/benefits" element={<Benifits/>} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
        <Route path="/login" element={<LoginScreen />} />
        
        
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="*" element={<NotFound />} />

      </Routes>
    </div>
  );
}

export default App;
